import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./Assets/Index.css";
import "./Assets/Color.css";

import {
  AdminNavigationData,
  InfoCardData,
} from "./Components/Navigation";
import { alias, SiteLoader } from "./utils";

import "./Auth/Auth.css";
import "./Admin/css/styles.css";
import "./Admin/css/NavigationBar.css";
import "./Admin/css/NavigationBar2.css";
import "./Admin/css/SearchBar.css";
import "./Admin/css/Card.css";
import "./Admin/css/Employee.css";
import "./Admin/css/Project.css";
import "./Admin/css/DatePicker.css";
import { Provider } from "react-redux";
import store from "./store";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ToastHandler from "./helpers/ToastHandler";
import ProtectedRoute from "./Admin/components/ProtectedRoute";
import { pdfjs } from "react-pdf";
import Unauthorized from "./Admin/views/Unauthorized";
import ForgotPassword from "./Auth/views/ForgotPassword";
import ResetPassword from "./Auth/views/ResetPassword";
import axios from "axios";
import { SocketProvider } from "./helpers/SocketContext";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();
const AdIndex = lazy(() => import("./Admin/Index"));
const Dashboard = lazy(() => import("./Admin/views/Dashboard"));

const Project = lazy(() => import("./Admin/views/Projects"));
// const AuIndex = lazy(() => import("./Auth/Index"));
const Login = lazy(() => import("./Auth/views/Login"));
const root = ReactDOM.createRoot(document.getElementById("root"));

function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
          <SocketProvider>
            <BrowserRouter>
              {/* <BrowserRouter basename={alias}> */}
              <Suspense fallback={<SiteLoader />}>
                <Routes>
                  <Route path="/" element={<Login />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/reset-password/:token" element={<ResetPassword />} />
                  <Route element={<ProtectedRoute />}>
                    <Route
                      path="/"
                      element={<AdIndex navigationData={AdminNavigationData} infoCardData={InfoCardData} />}
                    >
                      <Route path="p/:projectId/t/:taskId" element={<Project />} />
                      <Route path="projects/:projectId" element={<Project />} />
                      <Route path="dashboard" element={<Dashboard />} />
                      <Route path="projects" element={<Project />} />
                      <Route path="dashboard/:id" element={<Dashboard />} />
                      <Route path="unauthorized" element={<Unauthorized />} />
                      {/* <Route path="client" element={<Client />} />
                    <Route path="client/:id" element={<Client />} />
                    <Route path="projectmanager" element={<ProjectManager />} />
                    <Route
                      path="projectmanager/:id"
                      element={<ProjectManager />}
                    /> */}
                    </Route>
                  </Route>
                  {/* <Route
                    path="/employee"
                    element={<AdIndex navigationData={EmployeeNavigationData} infoCardData={InfoCardData} />}
                  >
                    <Route path="dashboard" element={<Dashboard1 />} />
                    <Route path="projects" element={<Project2 />} />
                    <Route path="projects/:id" element={<Project2 />} />
                  </Route> */}
                  {/* <Route path="/auth" element={<AuIndex />}>
                  <Route path="login" element={<Login />} />
                </Route> */}
                </Routes>
              </Suspense>
            </BrowserRouter>
          </SocketProvider>
          <ToastHandler />
        </DndProvider>
      </Provider>
    </React.StrictMode>
  );
}

const UNAUTHORIZED = 406;
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === UNAUTHORIZED) {
      localStorage.clear();
      store.dispatch({ type: "RESET_STORE" });
      // window.location.href = "/task";
      const baseUrl = window.location.origin;
      let urlToNavigate;
      if (baseUrl.indexOf("localhost") > -1) {
        urlToNavigate = `${window.location.origin}/task`;
      } else if (baseUrl.indexOf("dev") > -1) {
        urlToNavigate = `${window.location.origin}/task`;
      } else {
        urlToNavigate = `${window.location.origin}`;
      }
      window.location.href = urlToNavigate;
    }
    return Promise.reject(error);
  }
);

root.render(<App />);
